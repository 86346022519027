import { useContext, useMemo } from "react";
import { Action, MoovAdminResource, Resource } from "api/Role.model";
import { UserContext } from "contexts/UserContext";
import useSuper from "./useSuper";

export default function useRbac(action: Action, resource: Resource | MoovAdminResource): boolean {
  const { user, userCan } = useContext(UserContext);
  const superUser = useSuper();
  const allowed = useMemo(
    () => superUser || !user || userCan(action, resource),
    [superUser, user, userCan, action, resource]
  );
  return allowed;
}
