import { FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  Alert,
  BaseButton,
  DollarCurrencyAmountInput,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  Icon
} from "@moovfinancial/cargo";
import { IconClear } from "@moovfinancial/cargo/src/components/Icons";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import { OnboardingContext, type Underwriting } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./Transactions.module.scss";

interface FormState {
  averageMonthlyTransactionVolume: number;
  averageTransactionSize: number;
  businessToBusinessPercentage: number;
  cardPresentPercentage: number;
  consumerToBusinessPercentage: number;
  debtRepaymentPercentage: number;
  ecommercePercentage: number;
  mailOrPhonePercentage: number;
  maxTransactionSize: number;
}

const mapUnderwritingToFormState = (underwriting: Underwriting | undefined): FormState => ({
  averageMonthlyTransactionVolume: underwriting?.averageMonthlyTransactionVolume || 0,
  averageTransactionSize: underwriting?.averageTransactionSize || 0,
  businessToBusinessPercentage:
    underwriting?.volumeByCustomerType?.businessToBusinessPercentage || 0,
  cardPresentPercentage: underwriting?.cardVolumeDistribution?.cardPresentPercentage || 0,
  consumerToBusinessPercentage:
    underwriting?.volumeByCustomerType?.consumerToBusinessPercentage || 0,
  debtRepaymentPercentage: underwriting?.cardVolumeDistribution?.debtRepaymentPercentage || 0,
  ecommercePercentage: underwriting?.cardVolumeDistribution?.ecommercePercentage || 0,
  mailOrPhonePercentage: underwriting?.cardVolumeDistribution?.mailOrPhonePercentage || 0,
  maxTransactionSize: underwriting?.maxTransactionSize || 0
});

const mapFormStateToUnderwriting = (
  underwriting: Underwriting,
  formState: FormState
): Underwriting => ({
  averageMonthlyTransactionVolume: formState.averageMonthlyTransactionVolume,
  averageTransactionSize: formState.averageTransactionSize,
  cardVolumeDistribution: {
    cardPresentPercentage: formState.cardPresentPercentage,
    debtRepaymentPercentage: formState.debtRepaymentPercentage,
    ecommercePercentage: formState.ecommercePercentage,
    mailOrPhonePercentage: formState.mailOrPhonePercentage
  },
  fulfillment: underwriting.fulfillment,
  maxTransactionSize: formState.maxTransactionSize,
  volumeByCustomerType: {
    businessToBusinessPercentage: formState.businessToBusinessPercentage,
    consumerToBusinessPercentage: formState.consumerToBusinessPercentage
  }
});

/**
 * Set up volume types and variables for percentage field logic.
 */

type Volumes = "cardVolume" | "customerVolume";

// Set up card volume (underwriting.cardVolumeDistribution).
const initialCardVolumeFields = {
  cardPresentPercentage: {},
  debtRepaymentPercentage: {},
  ecommercePercentage: {},
  mailOrPhonePercentage: {}
};
type CardVolumeKeys = keyof typeof initialCardVolumeFields;

// Set up customer volume (underwriting.volumeByCustomerType).
const initialCustomerVolumeFields = {
  businessToBusinessPercentage: {},
  consumerToBusinessPercentage: {}
};
type CustomerVolumeKeys = keyof typeof initialCustomerVolumeFields;

type VolumeKeys = CardVolumeKeys & CustomerVolumeKeys;

/**
 * The Transactions component is a form that submits transaction and volume data to underwriting.
 */
export function Transactions() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { underwriting, patchUnderwriting } = useContext(OnboardingContext);
  const [formState, setFormState] = useState<FormState>(mapUnderwritingToFormState(underwriting));
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());
  const [errors, setErrors] = useState<OnboardingErrors<Underwriting>>({});

  // Set up card volume state.
  const { fields: fieldsCardVolume } = useValidatedFields(initialCardVolumeFields);
  const [cardVolumeError, setCardVolumeError] = useState(false);
  const [cardVolumeWarning, setCardVolumeWarning] = useState("");
  const [cardVolumeTotal, setCardVolumeTotal] = useState(0);

  // Set up customer volume state.
  const { fields: fieldsCustomerVolume } = useValidatedFields(initialCustomerVolumeFields);
  const [customerVolumeError, setCustomerVolumeError] = useState(false);
  const [customerVolumeWarning, setCustomerVolumeWarning] = useState("");
  const [customerVolumeTotal, setCustomerVolumeTotal] = useState(0);

  // Get volume fields and state based on type.
  const getVolumeFields = useCallback(
    (volumeType: Volumes) => {
      const volumeFields = volumeType === "cardVolume" ? fieldsCardVolume : fieldsCustomerVolume;
      const volumeFieldKeys = Object.keys(volumeFields.getField()) as VolumeKeys[];
      const volumeFieldValues = Object.values(volumeFields.getField());
      const allVolumeFieldsDirty = volumeFieldValues.every((value) => {
        return value.isDirty;
      });

      return {
        volumeFields: volumeFields,
        volumeFieldKeys: volumeFieldKeys,
        allVolumeFieldsDirty: allVolumeFieldsDirty,
        volumeTotal: volumeType === "cardVolume" ? cardVolumeTotal : customerVolumeTotal,
        setVolumeTotal: volumeType === "cardVolume" ? setCardVolumeTotal : setCustomerVolumeTotal,
        setVolumeError: volumeType === "cardVolume" ? setCardVolumeError : setCustomerVolumeError,
        setVolumeWarning:
          volumeType === "cardVolume" ? setCardVolumeWarning : setCustomerVolumeWarning
      };
    },
    [cardVolumeTotal, customerVolumeTotal, fieldsCardVolume, fieldsCustomerVolume]
  );

  // Do the percentage fields add up to 100?
  const validatePercentageFields = useCallback(
    (total: number, volumeType: Volumes) => {
      const { setVolumeError } = getVolumeFields(volumeType);

      if (total !== 0 && total !== 100) {
        setVolumeError(true);
      } else {
        setVolumeError(false);
      }
    },
    [getVolumeFields]
  );

  // Fill fields that aren't dirty with 0.
  const fillPercentageFieldsWithZero = (volumeType: Volumes) => {
    const { volumeFields, volumeFieldKeys } = getVolumeFields(volumeType);

    volumeFieldKeys.forEach((key) => {
      if (!volumeFields.isDirty(key)) {
        setFormState((prev) => ({ ...prev, [key]: 0 }));
        volumeFields.setDirty(key);
      }
    });
  };

  // Update volume total based on volume type.
  const updateVolumeTotal = useCallback(
    (fields: number[], volumeType: Volumes) => {
      const { allVolumeFieldsDirty, setVolumeTotal } = getVolumeFields(volumeType);
      const total = fields.reduce((a, b) => a + b);

      if (total > 100 || allVolumeFieldsDirty) {
        validatePercentageFields(total, volumeType);
      }

      setVolumeTotal(total);
    },
    [getVolumeFields, validatePercentageFields]
  );

  // Update card volume states.
  useEffect(() => {
    const cardVolumeFields = [
      Number(formState.cardPresentPercentage),
      Number(formState.debtRepaymentPercentage),
      Number(formState.ecommercePercentage),
      Number(formState.mailOrPhonePercentage)
    ];
    updateVolumeTotal(cardVolumeFields, "cardVolume");
  }, [
    formState.cardPresentPercentage,
    formState.debtRepaymentPercentage,
    formState.ecommercePercentage,
    formState.mailOrPhonePercentage,
    updateVolumeTotal
  ]);

  // Update customer volume states.
  useEffect(() => {
    const customerVolumeFields = [
      Number(formState.businessToBusinessPercentage),
      Number(formState.consumerToBusinessPercentage)
    ];
    updateVolumeTotal(customerVolumeFields, "customerVolume");
  }, [
    formState.businessToBusinessPercentage,
    formState.consumerToBusinessPercentage,
    updateVolumeTotal
  ]);

  /**
   * Handles onChange for percentage fields.
   * - Removes any leading zeros.
   * - Updates state.
   */
  const handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentName = e.target.name;
    const value = e.target.value;
    const newValue = value.replace(/^0+(.+)/, "$1");

    setFormState((prev) => ({
      ...prev,
      [currentName]: newValue
    }));
  };

  /**
   * Handles onBlur for percentage fields.
   * - Error and warning handling.
   * - Marking fields as dirty.
   * - Calls autofill functions.
   * - Adjusts out-of-range numbers to 0 or 100.
   */
  const handlePercentBlur = (e: React.FocusEvent<HTMLInputElement>, volumeType: Volumes) => {
    const currentName = e.target.name;
    const value = e.target.value === "" ? "0" : e.target.value;
    const {
      volumeFields,
      volumeFieldKeys,
      allVolumeFieldsDirty,
      setVolumeError,
      setVolumeWarning,
      volumeTotal
    } = getVolumeFields(volumeType);
    const nonZeroFieldCount = volumeFieldKeys.filter((key) => formState[key]).length;

    setVolumeWarning("");
    volumeFields.setDirty(currentName as VolumeKeys);

    if (volumeTotal === 100 && !allVolumeFieldsDirty) {
      fillPercentageFieldsWithZero(volumeType);
      setVolumeWarning("The remaining fields have been autofilled to 0%. Please review.");
    }

    if (volumeTotal < 100 && nonZeroFieldCount === volumeFieldKeys.length - 1) {
      const remaining = 100 - volumeTotal;
      const zeroField = volumeFieldKeys.filter((key) => formState[key] === 0)[0];
      setFormState((prev) => ({ ...prev, [zeroField]: remaining }));
      setVolumeError(false);
      setVolumeWarning(`The remaining field has been autofilled to ${remaining}%. Please review.`);
    }

    if (Number(value) > 100) {
      setFormState((prev) => ({ ...prev, [currentName]: 100 }));
      setVolumeError(false);
      setVolumeWarning(
        "A field was more than the maximum and has been set to 100%. Please review."
      );
    } else if (Number(value) < 0) {
      setFormState((prev) => ({ ...prev, [currentName]: 0 }));
      setVolumeError(false);
      setVolumeWarning("A field was less than the minimum and has been set to 0%. Please review.");
    } else {
      setFormState((prev) => ({ ...prev, [currentName]: Number(value) }));
    }
  };

  /**
   * Handles submit for form.
   * - Patches underwriting.
   * - Throws error, if applicable.
   * - Sends user to next step.
   */
  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    validatePercentageFields(cardVolumeTotal, "cardVolume");
    validatePercentageFields(customerVolumeTotal, "customerVolume");

    const { data, error } = await patchUnderwriting(
      mapFormStateToUnderwriting(underwriting, formState)
    );

    if (error) {
      setErrors(parseErrors<Underwriting>(error));
      toast("Unable to update account. Please try again.");
      return;
    }

    if (data) {
      void navigate(getNextStepUrl());
    }
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <h2 className={styles.header}>Transactions</h2>
      <div className={styles.row}>
        <p className={styles.pageInfoText}>
          Tell us about the type and quantity of transactions you expect.
        </p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className={styles.row}>
          <h3 className={styles.fieldGroupHeading}>Transaction amounts ($)</h3>
        </div>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={DollarCurrencyAmountInput}
            error={handleError(errors.averageMonthlyTransactionVolume)}
            label="Average monthly volume"
            name="averageMonthlyTransactionVolume"
            onValueChange={(value: number) =>
              setFormState((prev) => ({ ...prev, averageMonthlyTransactionVolume: value }))
            }
            placeholder="Average monthly volume"
            value={formState.averageMonthlyTransactionVolume || 0}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={DollarCurrencyAmountInput}
            error={handleError(errors.maxTransactionSize)}
            label="Maximum transaction amount"
            name="maxTransactionSize"
            onValueChange={(value: number) =>
              setFormState((prev) => ({ ...prev, maxTransactionSize: value }))
            }
            placeholder="Maximum transaction amount"
            value={formState.maxTransactionSize || 0}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={DollarCurrencyAmountInput}
            error={handleError(errors.averageTransactionSize)}
            label="Average transaction amount"
            name="averageTransactionSize"
            onValueChange={(value: number) =>
              setFormState((prev) => ({ ...prev, averageTransactionSize: value }))
            }
            placeholder="Average transaction amount"
            value={formState.averageTransactionSize || 0}
          />
        </FormGroup>
        <div className={styles.row}>
          <h3 className={styles.fieldGroupHeading}>What is the makeup of your volume?</h3>
        </div>
        <FormGroup noGap={false} className={styles.twoColumns}>
          <FloatingLabelInput
            error={
              cardVolumeError || handleError(errors.cardVolumeDistribution?.ecommercePercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCardVolume.isDirty("ecommercePercentage")}
            label={"Online (%)"}
            name="ecommercePercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "cardVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.ecommercePercentage || "0"}
            warning={!!cardVolumeWarning}
          />
          <FloatingLabelInput
            error={
              cardVolumeError || handleError(errors.cardVolumeDistribution?.cardPresentPercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCardVolume.isDirty("cardPresentPercentage")}
            label={"In person (%)"}
            name="cardPresentPercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "cardVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.cardPresentPercentage || "0"}
            warning={!!cardVolumeWarning}
          />
        </FormGroup>
        <FormGroup noGap={false} className={styles.twoColumns}>
          <FloatingLabelInput
            error={
              cardVolumeError || handleError(errors.cardVolumeDistribution?.mailOrPhonePercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCardVolume.isDirty("mailOrPhonePercentage")}
            label={"Mail/phone (%)"}
            name="mailOrPhonePercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "cardVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.mailOrPhonePercentage || "0"}
            warning={!!cardVolumeWarning}
          />
          <FloatingLabelInput
            error={
              cardVolumeError || handleError(errors.cardVolumeDistribution?.debtRepaymentPercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCardVolume.isDirty("debtRepaymentPercentage")}
            label={"Debt repayment (%)"}
            name="debtRepaymentPercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "cardVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.debtRepaymentPercentage || "0"}
            warning={!!cardVolumeWarning}
          />
        </FormGroup>
        {cardVolumeError && (
          <Alert
            className={styles.percentageAlert}
            label="The total of these 4 fields should add up to 100%"
            type="error"
          />
        )}
        {cardVolumeWarning && !cardVolumeError && (
          <Alert
            actionElement={
              <BaseButton
                aria-label="Close alert"
                centered
                onClick={() => setCardVolumeWarning("")}
                role="button"
              >
                <Icon iconComponent={IconClear} size="XS" />
              </BaseButton>
            }
            className={styles.percentageAlert}
            label={cardVolumeWarning}
            type="warning"
          />
        )}
        <div className={styles.row}>
          <h3 className={styles.fieldGroupHeading}>What is your volume by customer type?</h3>
        </div>
        <FormGroup noGap={false} className={styles.twoColumns}>
          <FloatingLabelInput
            error={
              customerVolumeError ||
              handleError(errors.volumeByCustomerType?.consumerToBusinessPercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCustomerVolume.isDirty("consumerToBusinessPercentage")}
            label={"Consumer (%)"}
            name="consumerToBusinessPercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "customerVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.consumerToBusinessPercentage || 0}
            warning={!!customerVolumeWarning}
          />
          <FloatingLabelInput
            error={
              customerVolumeError ||
              handleError(errors.volumeByCustomerType?.businessToBusinessPercentage)
            }
            inputMode="numeric"
            forceFloating={fieldsCustomerVolume.isDirty("businessToBusinessPercentage")}
            label={"Business  (%)"}
            name="businessToBusinessPercentage"
            max={100}
            maxLength={3}
            min={0}
            onBlur={(e) => handlePercentBlur(e, "customerVolume")}
            onChange={(e) => handlePercentChange(e)}
            type="number"
            value={formState.businessToBusinessPercentage || 0}
            warning={!!customerVolumeWarning}
          />
        </FormGroup>
        {customerVolumeError && !customerVolumeWarning && (
          <Alert
            className={styles.percentageAlert}
            label="The total of these 2 fields should add up to 100%"
            type="error"
          />
        )}
        {customerVolumeWarning && (
          <Alert
            allowClear
            className={styles.percentageAlert}
            label={customerVolumeWarning}
            type="warning"
          />
        )}
        <FooterButtons onBack={handleBackClick} onContinue={handleSubmit} />
      </form>
    </div>
  );
}
