import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router";
import useRouteBasedAccounts from "hooks/useRouteBasedAccounts";
import { OnboardingContextProvider } from "contexts/OnboardingContext";
import { OnboardingInviteContextProvider } from "contexts/OnboardingInviteContext";
import { OnboardingStepsContextProvider } from "contexts/OnboardingStepsContext";
import { WithOnboardingInviteActiveAccountID } from "pages/auth/WithOnboardingInviteActiveAccountID";
import Onboarding from "pages/onboarding/Onboarding";
import { ErrorPage } from "./ErrorPage";

export const OnboardingRoutes = () => {
  // this is needed for the account information to load. It should be refactored into a custom route component
  useRouteBasedAccounts();
  return (
    <OnboardingInviteContextProvider>
      <Routes>
        <Route path="*" element={<WithOnboardingInviteActiveAccountID />}>
          <Route
            path="*"
            element={
              <ErrorBoundary FallbackComponent={ErrorPage}>
                <OnboardingContextProvider>
                  <OnboardingStepsContextProvider>
                    <Onboarding />
                  </OnboardingStepsContextProvider>
                </OnboardingContextProvider>
              </ErrorBoundary>
            }
          />
        </Route>
      </Routes>
    </OnboardingInviteContextProvider>
  );
};
