let env: "development" | "staging" | "production";

// Used to show component `debug` messages on while debugging
export const shouldShowDebug = () => import.meta.env.VITE_DEBUG_UI === "1";

const isLocalHostname = (hostname: string) =>
  hostname === "localhost" || hostname.includes("local.moov.io");
const isLocalViteEnv = () => import.meta.env.DEV;
const isMockingEnabled = () => import.meta.env.VITE_MSW_BROWSER_MOCKING === "1";
const isStagingHostname = (hostname: string) => hostname.includes("moov-staging.");

const calculateEnvFromHostname = (shouldDisplayWarning = true) => {
  const hostname = window.location.hostname;
  const environment = isLocalHostname(hostname)
    ? "development"
    : isStagingHostname(hostname)
      ? "staging"
      : "production";
  if (environment !== "production" && shouldDisplayWarning) {
    console.warn(
      "🟠 No environment detected through Vite env variables, falling back to URL matching only"
    );
  }
  if (environment !== "production") {
    console.log(
      "ℹ️ Using URL matching to determine environment. This mode IS compatible with modResponse"
    );
  }
  return environment;
};

/**
 * Canonical environment detection for all monorepo apps
 *
 * 🟠 It works best with apps built with Vite
 *
 * If the required variables to determine the environment are not available,
 * it will make a best effort guess based on the hostname, and will log a warning in the console
 *
 * @param {boolean} urlOnlyEnvResolution - If true, only the URL will be used to determine the environment. This is useful for apps where we use modResponse to test them
 * @returns {string} - The environment name
 */
export const getEnv = (urlOnlyEnvResolution = false): typeof env => {
  if (env) return env;
  // import.meta.env.DEV is replaced at BUILD TIME (npm run build) by Vite with a boolean
  // indicating if the environment is development. Therefore it may be `undefined` if the app
  // is not built with Vite
  //
  // I was unsure if it might also throw an exception at runtime if the app was
  // not built with Vite, so being extra cautious and using a try/catch
  //
  // https://vitejs.dev/guide/env-and-mode.html#modes
  try {
    if (urlOnlyEnvResolution) {
      env = calculateEnvFromHostname(!urlOnlyEnvResolution);
      return env;
    }
    // Because Vite env will be "production" when the bundle is built, we can not conclude what the environment is only from the Vite env when
    // the Vite env is !== DEV (i.e. when !isLocalViteEnv()), therefore when that is false (we're for sure not in vite DEV mode), we continue resolving the
    // environment based on the URL, which might end up being "development", as the app may be running in p-dev, which is considered "development"
    env = isLocalViteEnv() ? "development" : calculateEnvFromHostname();
  } catch (_) {
    env = calculateEnvFromHostname();
  }
  return env;
};

export const isDevelopment = () => getEnv() === "development";
export const isProduction = () => getEnv() === "production";
export const isStaging = () => getEnv() === "staging";
// For testing purposes only
export const __test = {
  reset: () => {
    // @ts-expect-error - we know this is not a valid environment
    env = undefined;
  },
  calculateEnvFromHostname
};
export const shouldEnableMocks = () => isMockingEnabled() && isDevelopment();
