import clsx from "clsx";
import { HTMLAttributes, type JSX } from "react";
import { Loading } from "../../Foundations";
import { Card } from "../../Layout";
import styles from "./Table.module.scss";

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  // Expanded controls the vertical spacing between rows by adding addtional top and bottom padding to cells.
  // When set to true, will increase the top and bottom padding of cells to 1.5rem.
  // Typical use case for this is when your table only has single lines of text in each cell.
  expanded?: boolean;
  /**
   * The empty state to be rendered in the table when no data is present.
   */
  EmptyStateElement?: JSX.Element;
  /**
   * whether or not the table is currently empty
   */
  isEmpty?: boolean;
  /**
   * Whether the user is viewing the table in mobile or not.
   */
  isMobile?: boolean;
  /**
   * Whether the table should be displayed as / wrapped in a Card component.
   */
  asCard?: boolean;
  /**
   * Whether or not the last column's text should be right aligned.
   */
  lastColumnRight?: boolean;
  /**
   * TO DO: reexamine this implementation to utilize the SearchBar and Filter components once they are in cargo.
   */
  SearchAndFilterElement?: JSX.Element;
  /**
   * Whether the table is currently in a laoding state.
   */
  loading?: boolean;
}

/**
 * Primary UI component for building tables.
 */
export const Table = ({
  children,
  expanded = false,
  className,
  SearchAndFilterElement,
  EmptyStateElement,
  isEmpty,
  isMobile,
  asCard = false,
  lastColumnRight = false,
  loading,
  ...rest
}: TableProps) => {
  const getTableState = (): JSX.Element => {
    if (loading) return <Loading className={styles["table-loader"]} centered />;
    if (isEmpty && EmptyStateElement) return EmptyStateElement;
    return (
      <table
        className={clsx(
          styles.table,
          expanded && styles["table-expanded"],
          isMobile && styles.mobile,
          lastColumnRight && styles.lastColumnRight
        )}
        cellSpacing={0}
        {...rest}
      >
        {children}
      </table>
    );
  };
  return (
    <>
      {asCard ? (
        <Card className={clsx(styles.card, className)} noPadding>
          {SearchAndFilterElement}
          {getTableState()}
        </Card>
      ) : (
        <div className={styles.tableWrap}>
          {SearchAndFilterElement}
          {getTableState()}
        </div>
      )}
    </>
  );
};

const TableCell = ({ children, className, ...rest }: HTMLAttributes<HTMLTableCellElement>) => {
  return (
    <td className={clsx(className, styles["table-cell"])} {...rest}>
      {children}
    </td>
  );
};

const TableBody = ({ children, ...rest }: HTMLAttributes<HTMLTableSectionElement>) => {
  return <tbody {...rest}>{children}</tbody>;
};

const TableHeader = ({ children, ...rest }: HTMLAttributes<HTMLTableCellElement>) => {
  return (
    <th className={styles["table-header"]} {...rest}>
      {children}
    </th>
  );
};

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  isHeader?: boolean;
}

const TableRow = ({ children, isHeader, className, onClick, ...rest }: TableRowProps) => {
  if (isHeader) {
    return (
      <thead>
        <tr
          className={clsx(className, styles["table-row"], styles["table-header-row"])}
          data-testid="table-row"
          {...rest}
        >
          {children}
        </tr>
      </thead>
    );
  }
  return (
    <tr
      onClick={onClick}
      className={clsx(!!onClick && styles["table-row-clickable"], styles["table-row"], className)}
      data-testid="table-row"
      {...rest}
    >
      {children}
    </tr>
  );
};

Table.Cell = TableCell;
Table.Row = TableRow;
Table.Header = TableHeader;
Table.Body = TableBody;
