import clsx from "clsx";
import { HTMLAttributes, useContext } from "react";
import { useNavigate } from "react-router";
import { Alert, BaseButton } from "@moovfinancial/cargo";
import { StepContext } from "components/dialog/StepModal";
import { getRequirementLabel, listErrorsOrCurrentlyDueForAccount } from "helpers/capabilities";
import {
  RequirementLink,
  learnMoreToLink,
  requirementToLink
} from "pages/accounts/AccountDetail/capabilities/requirementMaps";
import { AccountSetupContext } from "../../AccountSetupContext";
import { StepID, stepMap } from "../Steps.model";
import styles from "../../../AccountDetail/CapabilitiesRequireAttentionBanner.module.scss";
import stepStyles from "./Success.module.scss";

export const CapabilitiesWarning = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  const { account, capabilityRequirements } = useContext(AccountSetupContext);
  const { goTo } = useContext(StepContext);
  const navigate = useNavigate();

  const listOfAccountIssues = listErrorsOrCurrentlyDueForAccount(capabilityRequirements);

  const handleClick = (link: RequirementLink | null) => {
    if (link === null) return;
    if (link.modal === "account") {
      return goTo(link.step);
    }
    if (link.modal === "representative") {
      return navigate(`/accounts/${account.accountID}/?representative=${link.step}`);
    }
  };

  const fieldRequirements = () => {
    {
      return listOfAccountIssues.map((issue) => (
        <li key={issue.requirement}>
          {getRequirementLabel(issue)}{" "}
          {learnMoreToLink[issue.requirement] && (
            <a
              href={learnMoreToLink[issue.requirement]}
              target="_blank"
              className={styles.learnMoreButton}
              rel="noreferrer"
            >
              Learn more
            </a>
          )}
          {requirementToLink[issue.requirement] &&
            requirementToLink[issue.requirement] !== null && (
              <BaseButton
                onClick={() => handleClick(requirementToLink[issue.requirement])}
                className={styles.fixNowButton}
              >
                Add now
              </BaseButton>
            )}
        </li>
      ));
    }
  };

  const stepRequirements = () => {
    const erroredSteps: StepID[] = [
      ...new Set(
        listOfAccountIssues
          .map((issue) => requirementToLink[issue.requirement]?.step)
          .filter((step): step is StepID => step !== undefined && step !== "list" && step !== "new")
      )
    ];

    return erroredSteps.map((step) => (
      <li key={step}>
        {stepMap[step].title}{" "}
        <BaseButton onClick={() => goTo(step)} className={styles.fixNowButton}>
          Add now
        </BaseButton>
      </li>
    ));
  };

  if (!listOfAccountIssues.length) return null;

  return (
    <Alert className={clsx(className, styles.alert, stepStyles.alert)} type="warning" {...rest}>
      Some information is missing:
      <div className={styles.list}>
        {listOfAccountIssues.length > 5 ? stepRequirements() : fieldRequirements()}
      </div>
    </Alert>
  );
};
