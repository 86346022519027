import clsx from "clsx";
import type { ReactNode } from "react";
import styles from "./DetailItem.module.scss";

interface DetailItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: string | number | ReactNode;
  alwaysShow?: boolean;
  alternateColor?: boolean;
  key?: string;
}

export const DetailItem = ({
  label,
  value,
  children,
  alwaysShow,
  alternateColor,
  key
}: DetailItemProps): ReactNode =>
  value || alwaysShow || children ? (
    <>
      <dt key={`${key}-label`}>{label}</dt>
      <dd key={`${key}-value`} className={clsx({ [styles.notProvided]: alternateColor })}>
        {children || value}
      </dd>
    </>
  ) : (
    <></>
  );

export default DetailItem;
