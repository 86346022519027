import { Suspense, useContext, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router";
import { Loading, PageFooter, PageHeader, Stepper } from "@moovfinancial/cargo";
import UserAvatar from "components/avatar/UserAvatar";
import { OnboardingContext } from "contexts/OnboardingContext";
import { OnboardingInviteContext } from "contexts/OnboardingInviteContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import FourOhFour from "pages/404";
import { BankAccountStep } from "./bank-account/BankAccountStep";
import { CompanyStep } from "./company/CompanyStep";
import { ExpectedActivityStep } from "./expected-activity/ExpectedActivityStep";
import { FinishStep } from "./finish/FinishStep";
import { OwnersStep } from "./owners/OwnersStep";
import { ReviewStep } from "./review/ReviewStep";
import styles from "./Onboarding.module.scss";

export default function Onboarding() {
  const { isLoading } = useContext(OnboardingContext);
  const { invite } = useContext(OnboardingInviteContext);
  const { steps } = useContext(OnboardingStepsContext);

  const isTestMode = useMemo(() => {
    if (!invite) return false;
    return invite.partner?.accountMode === "sandbox";
  }, [invite]);

  return isLoading ? (
    <Loading centered />
  ) : (
    <>
      <Helmet>
        <title>Create your account</title>
      </Helmet>
      <div className={styles.page}>
        <PageHeader
          secondaryLogo={
            invite?.partner ? (
              <UserAvatar account={invite.partner} sizeRem={2} className={styles.logoImage} />
            ) : null
          }
          testMode={isTestMode}
        />
        <main className={styles.main}>
          <Suspense fallback={<Loading centered />}>
            <Stepper steps={steps || []} />
            <Routes>
              <Route index element={<Navigate to="business/information" replace />} />
              <Route path="business/*" element={<CompanyStep />} />
              <Route path="owners/*" element={<OwnersStep />} />
              <Route path="expected-activity/*" element={<ExpectedActivityStep />} />
              <Route path="bank-account/*" element={<BankAccountStep />} />
              <Route path="review/*" element={<ReviewStep />} />
              <Route path="finish" element={<FinishStep />} />
              <Route path="*" element={<FourOhFour />} />
            </Routes>
          </Suspense>
        </main>
        <PageFooter />
      </div>
    </>
  );
}
