import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { DetailItem } from "components/lists/DetailItem";
import { MissingInfoWarning } from "./ReviewStep";
import styles from "./ReviewStep.module.scss";

export type VolumeByCustomerType = components["schemas"]["Underwriting"]["volumeByCustomerType"];

export const CustomerTypeDetails = ({
  volumeByCustomerType
}: {
  volumeByCustomerType: VolumeByCustomerType;
}) => {
  return (
    <DetailItem label="Customer type">
      {!!volumeByCustomerType?.consumerToBusinessPercentage && (
        <div
          className={styles.detailValue}
        >{`${volumeByCustomerType.consumerToBusinessPercentage}% Consumer`}</div>
      )}
      {!!volumeByCustomerType?.businessToBusinessPercentage && (
        <div
          className={styles.detailValue}
        >{`${volumeByCustomerType.businessToBusinessPercentage}% Business`}</div>
      )}
      {!volumeByCustomerType?.consumerToBusinessPercentage &&
        !volumeByCustomerType?.businessToBusinessPercentage && <MissingInfoWarning />}
    </DetailItem>
  );
};
