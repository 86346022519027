import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Notifications } from "api/v2/notifications.model";
import { APIContext } from "./APIContext";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

export interface NotificationsContextType {
  underwritingCount: number;
  verificationsCount: number;
  watchlistCount: number;
  matchCount: number;
  manualRepReviewsCount: number;
  pendingAdjustmentsCount: number;
  hasReviews: boolean;
  refreshNotifications: () => void;
}

export const NotificationsContext = createContext<NotificationsContextType>({
  underwritingCount: 0,
  verificationsCount: 0,
  watchlistCount: 0,
  matchCount: 0,
  manualRepReviewsCount: 0,
  pendingAdjustmentsCount: 0,
  hasReviews: false,
  refreshNotifications: () => {}
});

export default function NotificationsContextProvider({ children }: ContextProps) {
  const { activeUserAccountID } = useContext(UserContext);
  const [statuses, setStatuses] = useState<Notifications>({
    underwritingCount: 0,
    verificationsCount: 0,
    watchlistCount: 0,
    matchCount: 0,
    manualRepReviewsCount: 0,
    pendingAdjustmentsCount: 0
  });
  const intervalRef = useRef<ReturnType<typeof setInterval>>(undefined);

  const { moov } = useContext(APIContext);

  useEffect(() => {
    void getNotifications();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [activeUserAccountID]);

  const getNotifications = () => {
    if (activeUserAccountID !== "moov-admin") return;

    moov.notifications
      .get()
      .then(([result, error]) => {
        if (error || !result) return;

        const notificationCountsChanged = Object.entries(result).some(
          ([key, value]) => statuses[key as keyof Notifications] !== value
        );

        if (notificationCountsChanged) setStatuses(result);
      })
      .catch(() => {
        // Silent
      });
    if (!intervalRef.current) {
      const notificationInterval = setInterval(getNotifications, 300000);
      intervalRef.current = notificationInterval;
    }
  };

  const refreshNotifications = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    void getNotifications();
  };

  return (
    <NotificationsContext.Provider
      value={{
        underwritingCount: statuses.underwritingCount,
        verificationsCount: statuses.verificationsCount,
        watchlistCount: statuses.watchlistCount,
        matchCount: statuses.matchCount,
        manualRepReviewsCount: statuses.manualRepReviewsCount,
        pendingAdjustmentsCount: statuses.pendingAdjustmentsCount,
        hasReviews: !!Object.values(statuses).find((val) => val > 0),
        refreshNotifications
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
