"use client";

import { Link, To } from "react-router";
import { ButtonLike, ButtonLikeProps } from "../ButtonLike";

interface LinkAsButtonProps extends Omit<ButtonLikeProps<typeof Link>, "as"> {
  /**
   * The router link the button links to
   */
  to: To;
  /**
   * The text to be shown in the button. If passed along with children, it is ignored.
   */
  label?: string;
}

/**
 * LinkAsButton is a component that handles `<Link>` components (anchor tags)
 * that should visually appear like `<Button>` components.
 *
 * Meant to be used for navigation away from a page.
 */
export const LinkAsButton = ({ to, label, children, ...rest }: LinkAsButtonProps) => {
  return (
    <ButtonLike as={Link} to={to} label={label} data-testid="linkAsButton" {...rest}>
      {children}
    </ButtonLike>
  );
};
