import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Loading } from "@moovfinancial/cargo";
import { SessionContext } from "contexts/SessionContext";
import { UserContext } from "contexts/UserContext";
import SessionReminders from "layout/SessionReminders";

export const NeedsSession = () => {
  const { fetchSession, session } = useContext(SessionContext);
  const { reset: resetUserContext } = useContext(UserContext);
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const doFetchSession = async () => {
      await fetchSession();
      setIsReady(true);
    };
    if (!isReady) {
      void doFetchSession();
    }
  }, []);

  useEffect(() => {
    if (isReady && !session) {
      void navigate("/signin");
      resetUserContext();
    }
  }, [isReady, session]);

  return isReady && session ? (
    <>
      <Outlet />
      {/* Any time we have a session, we need session reminders */}
      <SessionReminders />
    </>
  ) : (
    // TODO: When React 18+ specs are more solidified, we should be able to use `use`
    // (https://react.dev/reference/react/use) // to signal to `Suspense` that we're stuff loading
    //and should show the loading spinner
    <Loading centered />
  );
};
