import clsx from "clsx";
import React, { FunctionComponent, forwardRef } from "react";
import { BaseButton, CargoElement } from "@moovfinancial/cargo";
import CheckMark from "components/icons/checkmark_circle.svg?react";
import CircleIcon from "components/icons/circle_outlined.svg?react";
import InfoIcon from "components/icons/info.svg?react";
import { Title } from "components/typography/Typography";
import { Placement } from "@popperjs/core";
import Tippy from "@tippyjs/react";
import styles from "./Form.module.scss";

interface FormProps {
  as?: string;
  type?: string;
  indeterminate?: boolean;
  isLocked?: boolean;
  warn?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export interface LabelProps {
  label?: string;
  optional?: boolean;
  optionalText?: string;
  infoText?: string;
}

export interface InfoTippyProps extends React.HTMLAttributes<HTMLSpanElement> {
  infoText?: string | React.ReactNode;
  placement?: Placement;
  trigger?: string;
}

export interface CheckboxProps {
  label?: string | React.ReactNode;
  reverse?: boolean;
}
interface FormMethodProps extends React.HTMLAttributes<HTMLFormElement> {
  method?: string;
}

// @TODO: Kill with fire
export const Form: FunctionComponent<React.PropsWithChildren<FormMethodProps>> = ({
  children,
  className,
  ...rest
}) => (
  <form className={className} {...rest}>
    {children}
  </form>
);

// @TODO: Move to cargo. Wrap infoIcon in <Icon />
export const InfoTippy = ({ infoText, className, placement, trigger }: InfoTippyProps) => {
  return (
    <span className={className}>
      <Tippy
        placement={placement || "top"}
        content={infoText}
        appendTo="parent"
        trigger={trigger}
        duration={300}
        delay={[0, 500]}
      >
        <span tabIndex={0}>
          <InfoIcon />
        </span>
      </Tippy>
    </span>
  );
};

// @TODO: Probably try to kill with fire, unless we see a very common pattern in current UIs
export const Label: FunctionComponent<
  React.PropsWithChildren<FormProps & LabelProps & React.AllHTMLAttributes<HTMLLabelElement>>
> = ({
  children,
  as,
  className,
  label,
  optional = false,
  optionalText = "(optional)",
  infoText,
  ...rest
}) => (
  <CargoElement renderAs={as || "label"} className={clsx(styles.label, className)} {...rest}>
    {(label || optional) && (
      <>
        <span>{label}</span>
        {infoText && <InfoTippy infoText={infoText} className={styles.labelInfo} />}
        {optional && <span className={styles.labelOptional}>{optionalText}</span>}
      </>
    )}
    {children && <span className={styles.labelChildren}>{children}</span>}
  </CargoElement>
);

interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  warn?: boolean;
}

// @TODO: Kill and replace with cargo's `Input` or `FloatingLabelInput`
export const Input = forwardRef<HTMLInputElement, InputProps>(function inputNested(
  { className, name, warn, ...rest },
  ref
) {
  return (
    <input
      className={clsx(styles.input, warn && styles.warn, className)}
      name={name}
      ref={ref}
      {...rest}
    />
  );
});

// @TODO FE-1048: Aggressively clean and improve, then move to cargo
export const Checkbox: FunctionComponent<
  React.PropsWithChildren<FormProps & CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>>
> = ({ className, indeterminate, type, label, reverse, disabled, ...rest }) => {
  return (
    <>
      {label && reverse && (
        <Label className={clsx(styles.checkboxLabelReverse, disabled && styles.disabledCheckbox)}>
          <span>{label}</span>
          <input
            ref={(ref) => {
              if (ref) {
                ref.indeterminate = indeterminate || false;
              }
            }}
            type={type || "checkbox"}
            className={clsx(styles.checkbox, className)}
            disabled={disabled}
            {...rest}
          />
        </Label>
      )}

      {label && !reverse && (
        <label className={clsx(disabled && styles.disabledCheckbox)}>
          <input
            ref={(ref) => {
              if (ref) {
                ref.indeterminate = indeterminate || false;
              }
            }}
            type={type || "checkbox"}
            className={clsx(styles.checkbox, className)}
            disabled={disabled}
            {...rest}
          />
          <span className={styles.checkLabel}>{label}</span>
        </label>
      )}

      {!label && (
        <input
          ref={(ref) => {
            if (ref) {
              ref.indeterminate = indeterminate || false;
            }
          }}
          type={type || "checkbox"}
          className={clsx(styles.checkbox, className)}
          disabled={disabled}
          {...rest}
        />
      )}
    </>
  );
};

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  ref?: React.RefObject<HTMLTextAreaElement | null>;
}

//@TODO: Probably move to cargo but adding the `error`, `warning`, `isErroring`, `isWarning`, etc. props a la Input
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { className, ...rest }: TextareaProps,
  ref
) {
  return <textarea className={clsx(styles.textarea, className)} ref={ref} {...rest}></textarea>;
});

//@TODO: Kill & replace with `FormGroup`, we may want to make FormGroup be a fieldset underneath?
export const Fieldset: FunctionComponent<
  React.PropsWithChildren<React.HTMLAttributes<HTMLFieldSetElement>>
> = ({ children, className, ...rest }) => (
  <fieldset className={clsx(styles.fieldset, className)} {...rest}>
    {children}
  </fieldset>
);

// @TODO: kill
export const Legend: FunctionComponent<
  React.PropsWithChildren<React.HTMLAttributes<HTMLLegendElement>>
> = ({ children, className, ...rest }) => (
  <legend className={clsx(styles.legend, className)} {...rest}>
    {children}
  </legend>
);
export interface RadioOptionData {
  value: string;
  label: string;
  secondaryLabel?: string;
}
interface RadioProps extends React.HTMLAttributes<HTMLDivElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioOptionData[];
  name: string;
  value: string;
  required?: boolean;
  stacked?: boolean;
  disabled?: boolean;
}

//@TODO: Aggressively clean & refactor, then move to cargo
export const RadioInput: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({
  onChange,
  options,
  name,
  value,
  required,
  disabled
}) => {
  return (
    <>
      {options.map((option) => {
        return (
          <div key={option.value} className={styles.optionContainer}>
            <input
              type="radio"
              checked={value === option.value}
              onChange={onChange}
              name={name}
              value={option.value}
              key={option.value}
              required={required}
              disabled={disabled}
            />{" "}
            <span
              className={styles.optionLabel}
              onClick={() =>
                onChange({
                  target: { value: option.value }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              {option.label}
            </span>
            {option.secondaryLabel && (
              <span className={styles.secondaryLabel}>{option.secondaryLabel}</span>
            )}
          </div>
        );
      })}
    </>
  );
};

/**
 * @deprecated Use cargo's ButtonToggle Instead
 */
export const LegacyButtonToggle: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({
  onChange,
  options,
  name,
  value,
  stacked,
  className,
  disabled,
  ...rest
}) => {
  return (
    <div
      className={clsx(className, styles.toggleButtonContainer, {
        [styles.stackedToggle]: stacked
      })}
      {...rest}
    >
      {options.map((option) => {
        return (
          <BaseButton
            key={option.value}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onChange({
                target: { value: option.value, name: name }
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            className={clsx(
              option.value === value ? styles.activeToggleButton : styles.inactiveToggleButton,
              styles.spread,
              stacked && styles.fullWidth
            )}
            disabled={disabled}
          >
            {option.secondaryLabel ? (
              <div className={styles.labelGrid}>
                <span className={styles.labelOne}>{option.label}</span>
                <span className={styles.labelTwo}>{option.secondaryLabel}</span>
              </div>
            ) : (
              <span>{option.label}</span>
            )}
            {option.value === value ? (
              <CheckMark className={styles.checkIcon} />
            ) : (
              <CircleIcon className={styles.circleIcon} />
            )}
          </BaseButton>
        );
      })}
      <input hidden value={value} name={name} onChange={() => {}} />
    </div>
  );
};

export const Toggle: FunctionComponent<
  React.PropsWithChildren<FormProps & CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>>
> = ({ className, label, ...rest }) => {
  return (
    <label className={styles.toggle}>
      <input type="checkbox" className={clsx(styles.switchInput, className)} {...rest} />
      <div className={styles.switch}></div>
      {label && (
        <Title as="span" className={styles.checkboxLabel}>
          {label}
        </Title>
      )}
    </label>
  );
};
