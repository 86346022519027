import clsx from "clsx";
import {
  type ChangeEvent,
  CompositionEvent,
  FormEventHandler,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  AddressAutoComplete,
  type AddressSuggestion,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  Icon,
  Select
} from "@moovfinancial/cargo";
import { IconTechnologyAndDataAi } from "@moovfinancial/cargo/icons";
import {
  armedForcesOptions,
  stateOptions,
  territoryOptions
} from "@moovfinancial/common/constants/state";
import { Checkbox } from "components/form/Form";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { type Account, OnboardingContext, type PatchAccount } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./CompanyAddress.module.scss";

interface FormState {
  business: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
  };
  customerSupport: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
  };
}

const mapAccountToFormState = (account: Partial<Account>): FormState => ({
  business: {
    addressLine1: account.profile?.business?.address?.addressLine1 ?? "",
    addressLine2: account.profile?.business?.address?.addressLine2 ?? "",
    city: account.profile?.business?.address?.city ?? "",
    stateOrProvince: account.profile?.business?.address?.stateOrProvince ?? "",
    postalCode: account.profile?.business?.address?.postalCode ?? "",
    country: account.profile?.business?.address?.country ?? "US"
  },
  customerSupport: {
    addressLine1: account.customerSupport?.address?.addressLine1 ?? "",
    addressLine2: account.customerSupport?.address?.addressLine2 ?? "",
    city: account.customerSupport?.address?.city ?? "",
    stateOrProvince: account.customerSupport?.address?.stateOrProvince ?? "",
    postalCode: account.customerSupport?.address?.postalCode ?? "",
    country: account.customerSupport?.address?.country ?? "US"
  }
});

const mapFormStateToAccount = (
  formState: FormState,
  isSameSupportAddress: boolean
): PatchAccount => ({
  profile: {
    business: {
      address: {
        addressLine1: formState.business.addressLine1,
        addressLine2: formState.business.addressLine2,
        city: formState.business.city,
        stateOrProvince: formState.business.stateOrProvince,
        postalCode: formState.business.postalCode,
        country: formState.business.country
      }
    }
  },
  customerSupport: {
    address: {
      addressLine1: isSameSupportAddress
        ? formState.business.addressLine1
        : formState.customerSupport.addressLine1,
      addressLine2: isSameSupportAddress
        ? formState.business.addressLine2
        : formState.customerSupport.addressLine2,
      city: isSameSupportAddress ? formState.business.city : formState.customerSupport.city,
      stateOrProvince: isSameSupportAddress
        ? formState.business.stateOrProvince
        : formState.customerSupport.stateOrProvince,
      postalCode: isSameSupportAddress
        ? formState.business.postalCode
        : formState.customerSupport.postalCode,
      country: isSameSupportAddress ? formState.business.country : formState.customerSupport.country
    }
  }
});

export function CompanyAddress() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { facilitatorID } = useContext(FacilitatorContext);
  const { account, patchAccount } = useContext(OnboardingContext);
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const [formState, setFormState] = useState<FormState>(mapAccountToFormState(account));
  const [errors, setErrors] = useState<OnboardingErrors<Account>>({});
  const addressesMatch = useMemo(
    () =>
      !!account.profile?.business?.address?.addressLine1 &&
      !!account.customerSupport?.address?.addressLine1 &&
      account.profile?.business?.address?.addressLine1.trim().toLocaleLowerCase() ===
        account.customerSupport?.address?.addressLine1.trim().toLocaleLowerCase() &&
      (account.profile?.business?.address?.addressLine2 ?? "").trim().toLocaleLowerCase() ===
        (account.customerSupport?.address?.addressLine2 ?? "").trim().toLocaleLowerCase() &&
      account.profile?.business?.address?.city.trim().toLocaleLowerCase() ===
        account.customerSupport?.address?.city.trim().toLocaleLowerCase() &&
      account.profile?.business?.address?.stateOrProvince.trim().toLocaleLowerCase() ===
        account.customerSupport?.address?.stateOrProvince.trim().toLocaleLowerCase() &&
      account.profile?.business?.address?.postalCode.trim().toLocaleLowerCase() ===
        account.customerSupport?.address?.postalCode.trim().toLocaleLowerCase(),
    [
      account.customerSupport?.address?.addressLine1,
      account.customerSupport?.address?.addressLine2,
      account.customerSupport?.address?.city,
      account.customerSupport?.address?.postalCode,
      account.customerSupport?.address?.stateOrProvince,
      account.profile?.business?.address?.addressLine1,
      account.profile?.business?.address?.addressLine2,
      account.profile?.business?.address?.city,
      account.profile?.business?.address?.postalCode,
      account.profile?.business?.address?.stateOrProvince
    ]
  );
  const [isSameSupportAddress, setIsSameSupportAddress] = useState(addressesMatch);
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    if (isSameSupportAddress) {
      const businessAddress = account.profile?.business?.address;
      setFormState((prev) => ({
        ...prev,
        customerSupport: { ...prev.customerSupport, address: businessAddress }
      }));
    }

    const { data, error } = await patchAccount(
      mapFormStateToAccount(formState, isSameSupportAddress)
    );

    if (error) {
      setErrors(parseErrors<Account>(error));
      toast("Unable to save account. Please check the form for errors and try again.");
      return;
    }

    if (data) {
      void navigate(getNextStepUrl());
    }
  };

  useEffect(() => {
    if (addressesMatch) {
      setIsSameSupportAddress(true);
    }
  }, [addressesMatch]);

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Business address</title>
      </Helmet>
      <h2 className={styles.header}>Business address</h2>
      <div className={clsx(styles.row, styles.autofill)}>
        <Icon iconComponent={IconTechnologyAndDataAi} size="M" />
        <p>We autofilled some fields based on your work email. Please check them for accuracy.</p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <header>
          <legend className={styles.fieldGroupHeading}>Legal address</legend>
          <p className={styles.fieldGroupDescription}>
            This can be found in the documents given to you when the business was formed.
          </p>
        </header>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={AddressAutoComplete}
            error={handleError(errors.profile?.business?.address?.addressLine1)}
            facilitatorID={facilitatorID}
            autoComplete="none"
            label="Street address"
            name="addressLine1"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormState((prev) => ({
                ...prev,
                business: { ...prev.business, addressLine1: e.target.value }
              }))
            }
            onSuggestionSelected={(suggestion: AddressSuggestion) =>
              setFormState((prev) => ({
                ...prev,
                business: {
                  ...prev.business,
                  addressLine1: suggestion.addressLine1 ?? prev.business.addressLine1,
                  addressLine2: suggestion.addressLine2 ?? "",
                  city: suggestion.city ?? prev.business.city,
                  postalCode: suggestion.postalCode ?? prev.business.postalCode,
                  stateOrProvince: suggestion.stateOrProvince ?? prev.business.stateOrProvince
                }
              }))
            }
            required
            value={formState.business.addressLine1}
          />
          <FloatingLabelInput
            label="Apartment, suite, or floor"
            name="addressLine2"
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                business: { ...prev.business, addressLine2: e.target.value }
              }))
            }
            value={formState.business.addressLine2}
            warning={handleError(errors.profile?.business?.address?.addressLine2)}
          />
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.address?.city)}
            label="City"
            name="city"
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                business: { ...prev.business, city: e.target.value }
              }))
            }
            required
            value={formState.business.city}
          />
          <div className={styles.twoColumns}>
            <Select
              className={styles.stateSelect}
              error={handleError(errors.profile?.business?.address?.stateOrProvince)}
              floatingLabelStyle
              label="State"
              name="stateOrProvince"
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  business: { ...prev.business, stateOrProvince: e.target.value }
                }))
              }
              placeholder="--"
              required
              value={formState.business.stateOrProvince}
            >
              {stateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
              <optgroup label="US outlying territories">
                {territoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Armed Forces">
                {armedForcesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            </Select>
            <FloatingLabelInput
              error={handleError(errors.profile?.business?.address?.postalCode)}
              inputMode="numeric"
              label="Zip code"
              maxLength={5}
              minLength={5}
              name="postalCode"
              onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                if (e.data.match(/[^0-9]/)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  business: { ...prev.business, postalCode: e.target.value }
                }))
              }
              pattern="[0-9]{5}"
              required
              type="text"
              value={formState.business.postalCode}
            />
          </div>
        </FormGroup>

        <header>
          <legend className={styles.fieldGroupHeading}>Business address</legend>
          <p className={styles.fieldGroupDescription}>
            This address is where you conduct your day to day business.
          </p>
        </header>

        <FormGroup noGap={false}>
          <Checkbox
            checked={isSameSupportAddress}
            label="My business address is the same as my legal address."
            onChange={(e) => setIsSameSupportAddress(e.target.checked)}
          />
        </FormGroup>

        {!isSameSupportAddress && (
          <FormGroup noGap={false}>
            <FloatingLabelWrapper
              as={AddressAutoComplete}
              error={handleError(errors.customerSupport?.address?.addressLine1)}
              facilitatorID={facilitatorID}
              autoComplete="none"
              label="Street address"
              name="addressLine1"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormState((prev) => ({
                  ...prev,
                  customerSupport: { ...prev.customerSupport, addressLine1: e.target.value }
                }))
              }
              onSuggestionSelected={(address: AddressSuggestion) =>
                setFormState((prev) => ({
                  ...prev,
                  customerSupport: {
                    ...prev.customerSupport,
                    addressLine1: address.addressLine1 ?? prev.customerSupport.addressLine1,
                    addressLine2: address.addressLine2 ?? "",
                    city: address.city ?? prev.customerSupport.city,
                    postalCode: address.postalCode ?? prev.customerSupport.postalCode,
                    stateOrProvince: address.stateOrProvince ?? prev.customerSupport.stateOrProvince
                  }
                }))
              }
              required
              value={formState.customerSupport.addressLine1}
            />
            <FloatingLabelInput
              label="Apartment, suite, or floor"
              name="support.addressLine2"
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  customerSupport: { ...prev.customerSupport, addressLine2: e.target.value }
                }))
              }
              value={formState.customerSupport.addressLine2}
              warning={handleError(errors.customerSupport?.address?.addressLine2)}
            />
            <FloatingLabelInput
              error={handleError(errors.customerSupport?.address?.city)}
              label="City"
              name="support.city"
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  customerSupport: { ...prev.customerSupport, city: e.target.value }
                }))
              }
              required
              value={formState.customerSupport.city}
            />
            <div className={styles.twoColumns}>
              <Select
                className={styles.stateSelect}
                error={handleError(errors.customerSupport?.address?.stateOrProvince)}
                floatingLabelStyle
                label="State"
                name="support.stateOrProvince"
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    customerSupport: { ...prev.customerSupport, stateOrProvince: e.target.value }
                  }))
                }
                placeholder="--"
                required
                value={formState.customerSupport.stateOrProvince}
              >
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <optgroup label="US outlying territories">
                  {territoryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Armed Forces">
                  {armedForcesOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              </Select>
              <FloatingLabelInput
                error={handleError(errors.customerSupport?.address?.postalCode)}
                inputMode="numeric"
                label="Zip code"
                maxLength={5}
                minLength={5}
                name="support.postalCode"
                onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                  if (e.data.match(/[^0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    customerSupport: { ...prev.customerSupport, postalCode: e.target.value }
                  }))
                }
                pattern="[0-9]{5}"
                required
                value={formState.customerSupport.postalCode}
              />
            </div>
          </FormGroup>
        )}

        <FooterButtons onBack={handleBackClick} onContinue={handleSubmit} />
      </form>
    </div>
  );
}
