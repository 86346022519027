import { getEnv } from "@moovfinancial/common/utils/env";
import { HoneycombWebSDK, WebVitalsInstrumentation } from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

const APP_NAME = "dashboard";
// @TODO: https://moovfinancial.slack.com/archives/C01882Q7DHU/p1735910906692259
const PROD_COLLECTOR_URL = "TBD"; // e.g. "https://moov.io/api/v1/traces";
const ENV = getEnv();

const STAGING_INGESTION_KEY = "hcaik_01jgyetv2ve584maajfgprk2sec0hbt6nm773900ckwghqwz54vjxt7v47";
const DEV_INGESTION_KEY = "hcaik_01jgyewpf57ngga56rhftkmf7b7r2ffmgsedbr55g426054mc3gpryrrka";

const initWithApiKey = () => {
  const sdk = new HoneycombWebSDK({
    apiKey: ENV === "development" ? DEV_INGESTION_KEY : STAGING_INGESTION_KEY,
    serviceName: APP_NAME,
    instrumentations: [getWebAutoInstrumentations(), new WebVitalsInstrumentation()] // add automatic instrumentation
  });
  sdk.start();
  console.log(`🌍 OpenTelemetry initialized with ${ENV} Honeycomb API ingestion key`);
};

// https://github.com/honeycombio/honeycomb-opentelemetry-web?tab=readme-ov-file#send-to-an-opentelemetry-collector
const initProd = () => {
  return; // for now do nothing in prod
  // @TODO after we have a collector
  const sdk = new HoneycombWebSDK({
    endpoint: PROD_COLLECTOR_URL,
    serviceName: APP_NAME,
    skipOptionsValidation: true, // because we are not including apiKey
    instrumentations: [getWebAutoInstrumentations(), new WebVitalsInstrumentation()] // add automatic instrumentation
  });
  sdk.start();
};

/**
 * The function automatically detects the environment we're in and initializes OpenTelemetry
 * with the API key for the correct environment, or with the production collector
 * for production environments.
 */
export const initOpenTelemetry = () => {
  // If we're in  dev or staging, we init OpenTelemetry with the correct api key for the given environment
  if (ENV === "development" || ENV === "staging") initWithApiKey();
  // Else, we use the production collector to avoid exposing our apiKeys in our FE code publicly
  else initProd();
};
