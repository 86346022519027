import { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Loading } from "@moovfinancial/cargo";
import { getOpenApiClient } from "@moovfinancial/common/api/OpenApiClientFactory";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { OnboardingInviteContext } from "contexts/OnboardingInviteContext";
import { UserContext } from "contexts/UserContext";

type Account = components["schemas"]["Account"];

const fetchInvite = async (token?: string, xAccountID?: string) => {
  if (!token || !xAccountID) return;
  const { data } = await getOpenApiClient().GET("/onboarding-invites/{code}", {
    params: {
      path: { code: token }
    },
    headers: {
      "X-Account-ID": xAccountID
    }
  });
  return data;
};

export const WithOnboardingInviteActiveAccountID = () => {
  const { user, activeUserAccountID } = useContext(UserContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const userContext = useContext(UserContext);
  const { setInvite } = useContext(OnboardingInviteContext);
  const navigate = useNavigate();
  const { token } = useParams();
  const { openApi } = useOpenApi();

  useEffect(() => {
    const selectAccount = async () => {
      if (!user || !user.userID) return;

      const { data: userAccounts } = await openApi.GET("/users/{userID}/accounts", {
        params: {
          path: { userID: user?.userID }
        }
      });

      const hasAccounts = userAccounts && userAccounts.length > 0;

      if (!hasAccounts) {
        void navigate("/create-account");
        return;
      }

      // We don't know which of the accountIDs the user is linked to is the one that has the invite pending.
      // The call will succeed with any accountID, as long as the token is valid.
      const invite = await fetchInvite(token, userAccounts[0].accountID);
      if (invite) {
        // if, somehow this invite has not been redeemed yet, we should kick the user back to the
        // hosted-onboarding flow for the user to redeem it
        if (invite.redeemedAccountID === undefined || invite.redeemedAccountID === null) {
          const redirectUrl = `${window.location.protocol}//${window.location.host}/o/${token}/`;
          window.location.href = redirectUrl;
          return;
        }
        const userBelongsToRedeemedAccount = userAccounts.some(
          (ua: Account) => ua.accountID === invite.redeemedAccountID
        );
        // Happy path
        if (userBelongsToRedeemedAccount) {
          // We need to set the active account as the redeemedAccountID to handle cases where the user has multiple accounts
          // Either:
          //
          // - userContext.setActiveUserAccountID or
          // - facilitatorContext.setFacilitatorByID(invite.redeemedAccountID) work well
          //
          // but not accountContext.setAccountByID(invite.redeemedAccountID) from AccountContext 😅
          userContext.setActiveUserAccountID(invite.redeemedAccountID);
          setInvite(invite);
          return;
        }
      }

      // if !invite || !userBelongsToRedeemedAccount
      // If the user does not belong to the accountID that redeemed the invite, we show an error and navigate to the home page
      toast.error("This invite does not exist or you have no access to it", {
        type: "error",
        autoClose: 10000
      });
      void navigate("/");
    };
    void selectAccount();
  }, [user]);

  const canRender = !!user && !!activeUserAccountID && !!facilitatorID;
  return canRender ? <Outlet /> : <Loading centered />;
};
