import { FormEventHandler, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormGroup, Icon } from "@moovfinancial/cargo";
import { IconInfoOutlined } from "@moovfinancial/cargo/icons";
import { anyResponseErrorToString } from "@moovfinancial/common/api/legacyRequest";
import Accordion from "components/accordion/Accordion";
import { DocumentUpload, FileForUploadState } from "components/form/DocumentUpload";
import { Checkbox } from "components/form/Form";
import { FileResponse, OnboardingContext } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { parseErrors } from "../helpers/errors";
import styles from "./ProcessingStatements.module.scss";

function isFileResponse(x: File | FileResponse | undefined | null): x is FileResponse {
  return !!x && "fileID" in x;
}
function provideFileStatus(
  file: File | FileResponse,
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState;
function provideFileStatus(
  files: (File | FileResponse)[],
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState[];
function provideFileStatus(
  files: File | FileResponse | (File | FileResponse)[],
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState | FileForUploadState[] {
  const wasArray = Array.isArray(files);
  const fileArray = wasArray ? files : [files];
  const resultArray = fileArray.map<FileForUploadState>((f) =>
    isFileResponse(f)
      ? { errorMessage, file: new File([], f.fileName ?? "Unknown file"), status }
      : { errorMessage, file: f, status }
  );
  return wasArray ? resultArray : resultArray[0];
}

export function ProcessingStatements() {
  const { files, uploadFiles, certifiedNoStatements, setCertifiedNoStatements } =
    useContext(OnboardingContext);
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const navigate = useNavigate();
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesWithStatus, setFilesWithStatus] = useState<FileForUploadState[]>(
    provideFileStatus(files, "success")
  );

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    const response = await uploadFiles(filesToUpload);

    const erroredFiles = response.filter(
      (r) => r.status === "rejected" || (r.status === "fulfilled" && !r.value.response.ok)
    );
    const updatedFiles = filesToUpload.map<FileForUploadState>((file, index) => {
      const result = response[index];
      if (result.status === "fulfilled" && result.value.response.ok) {
        return provideFileStatus(file, "success");
      }
      const errorMessage =
        result.status === "rejected"
          ? JSON.stringify(result.reason)
          : anyResponseErrorToString(parseErrors<FileResponse>(result.value.error));
      return provideFileStatus(file, "error", errorMessage || "An unknown error has occurred");
    });

    setFilesWithStatus([...provideFileStatus(files, "success"), ...updatedFiles]);

    if (erroredFiles.length > 0) {
      toast("There was an error uploading your processing statements");
      return;
    } else {
      // TODO: Do something with the certifiedNoStatements once the API supports it
      void navigate(getNextStepUrl());
    }
  };

  const handleAddFile = (file: File) => {
    setFilesToUpload((prev) => [...prev, file]);
    setFilesWithStatus((prev) => [...prev, { file, status: "pending" } as const]);
  };

  const handleDeleteFile = (name: string) => {
    setFilesToUpload((prev) => prev.filter((f) => f.name !== name));
    setFilesWithStatus((prev) =>
      prev.filter((f) => !(f.status === "pending" && f.file.name === name))
    );
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Processing statements</title>
      </Helmet>
      <h2 className={styles.header}>Processing statements</h2>
      <div className={styles.row}>
        <p className={styles.pageInfoText}>
          Provide processing statements from the past three months. If the business is seasonal,
          provide processing statements for the three busiest months.
        </p>
      </div>
      <DocumentUpload
        addFile={handleAddFile}
        onDelete={handleDeleteFile}
        selectedFiles={filesWithStatus}
      />
      {!files.length && (
        <Accordion>
          <Accordion.Section
            className={styles.accordion}
            collapsed
            title="Don't have any processing statements?"
            badge={<Icon iconComponent={IconInfoOutlined} size="XS" />}
          >
            <FormGroup className={styles.body} noGap={false}>
              <Checkbox
                checked={certifiedNoStatements}
                label="I certify that my company has never accepted card payments and has no processing
          statements to provide."
                onChange={(e) => setCertifiedNoStatements(e.target.checked)}
              />
            </FormGroup>
          </Accordion.Section>
        </Accordion>
      )}
      <FooterButtons
        disabled={!files.length && !filesToUpload.length && !certifiedNoStatements}
        onBack={handleBackClick}
        onContinue={handleSubmit}
      />
    </div>
  );
}
