import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router";
import { Loading } from "@moovfinancial/cargo";
import { OnboardingContext } from "contexts/OnboardingContext";
import { CompanyAddress } from "./CompanyAddress";
import { CompanyInformation } from "./CompanyInformation";

export function CompanyStep() {
  const { isLoading } = useContext(OnboardingContext);
  return isLoading ? (
    <Loading />
  ) : (
    <Routes>
      <Route index element={<Navigate to="information" replace />} />
      <Route path="information" element={<CompanyInformation />} />
      <Route path="address" element={<CompanyAddress />} />
    </Routes>
  );
}
