import React, {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import useInputValidation from "hooks/useInputValidation";
import { ToastEntry } from "components/toaster/Toaster";
import { Account, AccountUnderwriting, Capability, CapabilityName } from "api/v2";
import { APIContext } from "contexts/APIContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { generateSandboxData } from "pages/auth/createFirstAccount";
import { Action, type ActionType, FileState, initialState, reducer } from "./AccountSetupReducer";

export interface AccountSetupContextType {
  // Current account state, not necessarily the final account
  account: DeepPartial<Account>;
  // Account state, if already created.
  savedAccountData: MutableRefObject<DeepPartial<Account>>;
  // Capabilities that the account will or does have
  capabilities: CapabilityName[];
  // Set the capabilities that the account will or does have
  setCapabilities: (capabilities: CapabilityName[]) => void;
  // Dispatch function for the reducer
  dispatch: (action: Action) => void;
  // Whether the account was prefilled with sandbox data
  prefilled: boolean;
  // Whether the provided refresh function should be called.
  shouldRefresh: MutableRefObject<boolean>;
  // Whether or not there is unsaved data in the account form.
  hasUnsavedData: MutableRefObject<boolean>;
  // Underwriting data for the account
  underwriting: AccountUnderwriting;
  // Files that have been uploaded for the account
  files: FileState[];
  // Whether the address is being entered manually
  manualAddress: boolean;
  //Errors to be displayed in the toaster
  errorMessages: ToastEntry[];
  // Set the errors to be displayed in the toaster
  setErrorMessages: (errors: ToastEntry[]) => void;
  // List of current capability requirements
  capabilityRequirements: Capability[];
  // Set the list of current capability requirements
  setCapabilityRequirements: (requirements: Capability[]) => void;
  // Whether the given input is errored
  isErrored: (name: string) => boolean;
  // List of inputs that are currently invalid;
  invalidInputs: string[];
  // Set the list of inputs that are currently invalid;
  setInvalidInputs: React.Dispatch<React.SetStateAction<string[]>>;
  // validate an input
  validate: (input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) => void;
  // reset an input
  resetInput: (name: string) => void;
  // Pricing plan code
  planCodes: string[];
  // Sets the plan code
  setPlanCodes: (planCodes: string[]) => void;
}

export const defaultCapabilities: CapabilityName[] = ["transfers"];

export const AccountSetupContext = createContext<AccountSetupContextType>({
  account: {},
  savedAccountData: { current: {} },
  capabilities: defaultCapabilities,
  setCapabilities: () => {},
  dispatch: () => {},
  prefilled: false,
  shouldRefresh: { current: false },
  hasUnsavedData: { current: false },
  underwriting: {},
  files: [],
  manualAddress: false,
  errorMessages: [],
  setErrorMessages: () => {},
  capabilityRequirements: [],
  setCapabilityRequirements: () => {},
  isErrored: () => false,
  invalidInputs: [],
  setInvalidInputs: () => {},
  validate: () => {},
  resetInput: () => {},
  planCodes: [],
  setPlanCodes: () => {}
});

interface AccountDataProviderProps {
  // Account data to prefill the form with - ususally the existing account data.
  accountData?: DeepPartial<Account>;
  // Underwriting data to prefill the form with - usually the existing underwriting data.
  underwritingData?: AccountUnderwriting;
  // Capabilities data to prefill the form with - usually the existing capabilities data.
  capabilitiesData?: Capability[];
  children: React.ReactNode;
}

const AccountSetupProvider = ({
  children,
  accountData,
  underwritingData,
  capabilitiesData
}: AccountDataProviderProps) => {
  const { mode } = useContext(FacilitatorContext);
  const { moov } = useContext(APIContext);

  const [state, dispatchState] = useReducer(reducer, initialState);

  const { invalidInputs, setInvalidInputs, erroredInputs, setErroredInputs, validate, resetInput } =
    useInputValidation();

  const [capabilities, setCapabilities] = useState<CapabilityName[]>(defaultCapabilities);
  const [errorMessages, setErrorMessages] = useState<ToastEntry[]>([]);
  const [capabilityRequirements, setCapabilityRequirements] = useState<Capability[]>(
    capabilitiesData || []
  );
  const [planCodes, setPlanCodes] = useState<string[]>([]);
  const shouldRefresh = useRef(false);
  const hasUnsavedData = useRef(false);
  const savedAccountData = useRef<DeepPartial<Account>>({});

  useEffect(() => {
    if (errorMessages.length) {
      setErroredInputs(errorMessages.map((message) => message.key));
      setTimeout(() => {
        setErrorMessages([]);
      }, 4500);
    }
  }, [errorMessages]);

  useEffect(() => {
    if (mode === "sandbox" && !state.prefilled && !accountData) {
      void generateSandboxData(moov).then((data) => {
        dispatchState({ type: "prefill", value: data });
      });
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      savedAccountData.current = deepMerge(initialState.account, accountData) as Account;
      dispatchState({
        type: "account",
        value: deepMerge(initialState.account, accountData) as Account
      });
    }
  }, [accountData]);

  useEffect(() => {
    if (underwritingData) {
      dispatchState({
        type: "underwriting",
        value: underwritingData
      });
    }
  }, [underwritingData]);

  const isErrored = (key: string) => erroredInputs.includes(key);

  //resets hasUnsavedData when changes are made to the account.
  const shouldReset: ActionType[] = [
    "account",
    "individual",
    "business",
    "individualName",
    "individualPhone",
    "individualAddress",
    "individualBirthdate",
    "businessAddress",
    "businessPhone",
    "businessTaxID",
    "underwriting"
  ];
  const dispatch = (action: Action) => {
    if (action.type && shouldReset.includes(action.type)) {
      hasUnsavedData.current = true;
    }
    dispatchState(action);
  };

  const value = {
    account: state.account,
    savedAccountData,
    capabilities,
    setCapabilities,
    dispatch,
    prefilled: state.prefilled,
    shouldRefresh,
    hasUnsavedData,
    underwriting: state.underwriting,
    files: state.files,
    manualAddress: state.manualAddress,
    capabilityRequirements,
    setCapabilityRequirements,
    errorMessages,
    setErrorMessages,
    isErrored,
    invalidInputs,
    setInvalidInputs,
    validate,
    resetInput,
    planCodes,
    setPlanCodes
  };

  return <AccountSetupContext.Provider value={value}>{children}</AccountSetupContext.Provider>;
};

export default AccountSetupProvider;
