import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router";
import useFlags from "hooks/useFlags";
import AccountContextProvider from "contexts/AccountContext";
import AdminAccountStatusContextProvider from "contexts/AdminAccountStatusContext";
import AvatarUrlsContextProvider from "contexts/AvatarUrlsContext";
import FacilitatorContextProvider from "contexts/FacilitatorContext";
import SessionContextProvider from "contexts/SessionContext";
import { NeedsSession } from "pages/auth/NeedsSession";
import { WithActiveAccountID } from "pages/auth/WithActiveAccountID";
import { DashboardRoutes } from "./DashboardRoutes";
import { OnboardingRoutes } from "./OnboardingRoutes";

export const PrivateRoutes = () => {
  const [hostedOnboardingEnabled] = useFlags(["hosted-onboarding"]);

  return (
    <SessionContextProvider>
      <FacilitatorContextProvider>
        <AccountContextProvider>
          <AdminAccountStatusContextProvider>
            <AvatarUrlsContextProvider>
              <AnimatePresence mode="wait" initial={false}>
                <Routes>
                  <Route path="/*" element={<NeedsSession />}>
                    {hostedOnboardingEnabled && (
                      <Route path="onboarding/:token/*" element={<OnboardingRoutes />} />
                    )}
                    <Route path="*" element={<WithActiveAccountID />}>
                      <Route path="*" element={<DashboardRoutes />} />
                    </Route>
                  </Route>
                </Routes>
              </AnimatePresence>
            </AvatarUrlsContextProvider>
          </AdminAccountStatusContextProvider>
        </AccountContextProvider>
      </FacilitatorContextProvider>
    </SessionContextProvider>
  );
};
