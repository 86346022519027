import React from "react";
import { Link } from "react-router";

export interface CargoElementProps
  extends React.HTMLAttributes<
    HTMLDivElement & HTMLLabelElement & HTMLAnchorElement & HTMLButtonElement
  > {
  renderAs?: string | typeof Link;
  as?: string;
}

export const CargoElement = React.forwardRef<HTMLDivElement, CargoElementProps>(
  function elementNested({ renderAs, as, ...props }, ref) {
    const RenderAs = as || renderAs || "div";
    // @ts-expect-error Type 'ForwardedRef<HTMLDivElement>' is not assignable to type 'Ref<HTMLAnchorElement> | undefined'. ...         Property 'align' is missing in type 'HTMLAnchorElement' but required in type 'HTMLDivElement'.ts(2322).
    return <RenderAs ref={ref} {...props} />;
  }
);
