import clsx from "clsx";
import { useMemo } from "react";
import { Badge, ButtonLegacy } from "@moovfinancial/cargo";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import useAccountLocking from "hooks/useAccountLocking";
import UserAvatar from "components/avatar/UserAvatar";
import ClearIcon from "components/icons/clear.svg?react";
import EditIcon from "components/icons/edit.svg?react";
import ErrorOutlinedIcon from "components/icons/error_outlined.svg?react";
import { Text } from "components/typography/Typography";
import { Representative } from "api/v2/accounts.model";
import { isRepresentativeComplete } from "../helpers/isRepresentativeComplete";
import styles from "./RepresentativeListItem.module.scss";

interface RepresentativeListItemProps {
  className?: string;
  missingController?: boolean;
  missingInfo?: boolean;
  onDelete?: (rep: DeepPartial<Representative>) => void;
  onEdit?: (rep: DeepPartial<Representative>) => void;
  representative: DeepPartial<Representative>;
  showOwnershipPercentage?: boolean;
}

export default function RepresentativeListItem({
  className,
  missingController,
  missingInfo: missingInfoProp,
  onDelete,
  onEdit,
  representative,
  showOwnershipPercentage
}: RepresentativeListItemProps) {
  const { verificationLocked: isLocked } = useAccountLocking();
  const missingInfo = useMemo(() => {
    return !isRepresentativeComplete(representative);
  }, [representative]);

  return (
    <div
      className={clsx(styles.representativeCard, className)}
      onClick={() => {
        if (onEdit) onEdit(representative);
      }}
      role="button"
    >
      <UserAvatar representative={representative} size={40} />
      <div className={styles.representativeInfo}>
        <div className={styles.name}>
          {representative.name?.firstName} {representative.name?.lastName}
          <div className={styles.hoverIcon}>
            <EditIcon />
          </div>
        </div>

        <div>
          <span className={styles.jobTitle}>
            {representative.responsibilities?.jobTitle ? (
              <>{representative.responsibilities.jobTitle}</>
            ) : null}
          </span>

          {showOwnershipPercentage && representative.responsibilities?.ownershipPercentage && (
            <span className={styles.ownershipPercentage}>
              {representative.responsibilities?.jobTitle && " - "}
              {representative.responsibilities.ownershipPercentage}% ownership
            </span>
          )}
        </div>

        {(missingInfoProp ?? missingInfo) ? (
          <div className={styles.missingInfoLabel}>
            <ErrorOutlinedIcon />
            &nbsp;<Text textStyle="footnote-regular">Missing information</Text>
          </div>
        ) : null}
        {missingController ? (
          <div className={styles.missingInfoLabel}>
            <ErrorOutlinedIcon />
            &nbsp;<Text textStyle="footnote-regular">Missing company controller</Text>
          </div>
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        {representative.responsibilities?.isController ? (
          <Badge
            color="blueDynamic"
            label="Control officer"
            className={clsx({ [styles.mr16]: !isLocked })}
          />
        ) : null}
        {!isLocked && onDelete && (
          <ButtonLegacy
            className={styles.delete}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(representative);
            }}
            icon
          >
            <ClearIcon />
          </ButtonLegacy>
        )}
      </div>
    </div>
  );
}
