import { AnimatePresence, motion } from "framer-motion";
import { Suspense, useContext } from "react";
import { Outlet } from "react-router";
import useSuper from "hooks/useSuper";
import { UserContext } from "contexts/UserContext";
import styles from "./ActiveAcctRoute.module.scss";

interface CustomProps {
  superUser?: boolean;
}

// TODO: I suspect everything going on in this component is already handled in `WithActiveAccountID` ??

export default function NeedsActiveAcct({ superUser: shouldBeSuper }: CustomProps) {
  const { activeUserAccountID } = useContext(UserContext);
  // TODO: This component should TRIGGER the check in `UserContext` to check if the user is super user
  // This process should happen asynchronously and this component should await that check
  // and show a loading state until the check is complete
  //
  // I suspect most of the logic in `dashboard/src/hooks/useUserAccountHistorySynchronizer.ts`
  // should be moved into `UserContext`
  const isSuper = useSuper();

  return (
    <Suspense fallback={<div></div>}>
      {activeUserAccountID && (
        <AnimatePresence mode="wait">
          {/* Temporary fix to prevent 404 flashing, just not show it until we properly fix all the race conditions */}
          {shouldBeSuper && !isSuper ? null : (
            <motion.div className={styles.container}>
              <Outlet />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Suspense>
  );
}
