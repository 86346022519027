import clsx from "clsx";
import { type JSX, useCallback, useState } from "react";
import { BaseButton } from "@moovfinancial/cargo";
import { Text } from "cleanComponents/Typography/Text";
import ChevronIcon from "../icons/chevron_down.svg?react";
import styles from "./Accordion.module.scss";

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Accordion({ children }: AccordionProps): React.ReactElement {
  return <>{children}</>;
}

export interface AccordionSectionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onToggle"> {
  collapsed?: boolean;
  title: string;
  subtitle?: string;
  badge?: JSX.Element;
  onToggle?: (isCollapsed: boolean) => void;
  actions?: JSX.Element;
  //Whether the actions should be placed to the left or right of the toggle icon
  actionsPlacement?: "left" | "right";
}

Accordion.Section = function AccordionSection({
  children,
  title,
  subtitle,
  className,
  collapsed = false,
  badge,
  onToggle,
  actions,
  actionsPlacement,
  ...rest
}: AccordionSectionProps): React.ReactElement {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    if (onToggle) onToggle(!isCollapsed);
  }, [isCollapsed]);

  return (
    <div className={clsx(className, !children && styles.empty)} {...rest}>
      <div className={styles.helmet} onClick={children ? handleClick : undefined}>
        <div className={styles.header}>
          <div>
            <h2>{title}</h2>
            {badge && <div className={styles.badge}>{badge}</div>}
          </div>
          <div>
            {actionsPlacement === "left" && actions}
            {children && (
              <BaseButton className={styles.icon}>
                <ChevronIcon className={clsx({ [styles.collapsed]: isCollapsed })} />
              </BaseButton>
            )}
            {actions && actionsPlacement !== "left" && actions}
          </div>
        </div>
        {subtitle && (
          <Text className={styles.subtitle} textStyle="body-s-regular">
            {subtitle}
          </Text>
        )}
      </div>
      <div className={clsx(styles.body, { [styles.collapsed]: isCollapsed })}>{children}</div>
    </div>
  );
};
