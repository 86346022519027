import { useContext, useEffect, useState } from "react";
import { ValidationMessage } from "@moovfinancial/cargo/src/components/Form";
import {
  Industry,
  backupIndustryCodes,
  getCodesByTitle,
  getTitleByCodes
} from "@moovfinancial/common/utils/Industry";
import ComboBox from "components/form/ComboBox";
import { Label } from "components/form/Form";
import { APIContext } from "contexts/APIContext";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

interface IndustrySelectorProps {
  errored?: boolean;
  isLocked?: boolean;
  isMissing?: boolean;
}

export const IndustrySelector = ({ errored, isLocked, isMissing }: IndustrySelectorProps) => {
  const { moov } = useContext(APIContext);
  const { account, dispatch, resetInput } = useContext(AccountSetupContext);

  const [industry, setIndustry] = useState("");
  const [industries, setIndustries] = useState<Industry[]>(backupIndustryCodes);

  useEffect(() => {
    let localCodes: Industry[] = backupIndustryCodes;
    void moov.profileEnrichment
      .getIndustries()
      .then(([result, error]) => {
        if (error) return; // Fail silently and use the in-memory industry codes list
        if (result?.industries?.length) {
          setIndustries(result.industries);
          localCodes = result.industries;
        }
      })
      .finally(() => {
        const codes = account.profile?.business?.industryCodes;
        if (codes?.mcc || codes?.sic || codes?.naics) {
          const industryValue = getTitleByCodes(codes, localCodes);
          if (industryValue) setIndustry(industryValue);
        }
      });
  }, [account]);

  useEffect(() => {
    const codes = getCodesByTitle(industry, industries);

    if (codes) {
      const { mcc, naics, sic } = codes;
      dispatch({
        type: "businessCodes",
        value: { mcc, sic, naics }
      });
    }
  }, [industry]);

  return (
    <div>
      <Label label="Industry - MCC" />
      <ComboBox
        className={styles.invalidInput} // This is not really invalid, just reusing the styles :sweat_smile:
        data={industries.map((code) => ({
          value: code.title ?? "",
          label: `${code.mcc}`
        }))}
        value={industry || ""}
        autoComplete="off"
        format="both"
        name="SICCode"
        onChange={(e) => {
          resetInput("industryCodes");
          setIndustry(e.target.value);
        }}
        disabled={isLocked}
        isLocked={isLocked}
        warn={isMissing && !industry}
      />
      {isMissing && !industry && (
        <ValidationMessage
          noMargins
          message={errored ? "Industry is invalid" : "Industry is missing or invalid."}
        />
      )}
    </div>
  );
};
