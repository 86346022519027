import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Location, useLocation, useNavigate } from "react-router";
import {
  Alert,
  Button,
  FloatingLabelInput,
  FloatingLabelPasswordInput,
  FormGroup,
  Loading
} from "@moovfinancial/cargo";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Form } from "components/form/Form";
import Toaster, { ToastInput } from "components/toaster/Toaster";
import { Text } from "components/typography/Typography";
import { InviteVerification } from "api/Invite.model";
import * as InvitesApi from "api/Invites";
import { DeviceVerification, type User } from "api/User.model";
import * as UsersApi from "api/Users";
import { UserContext } from "contexts/UserContext";
import { is4xx, universalErrorHandlerToString } from "helpers/errorMessages";
import MoovLogo from "images/moov.svg?react";
import GradientBackground from "./GradientBackground";
import { LocationState, calculatePathToRedirectToAfterVerification } from "./signInRedirectHelper";
import styles from "./Auth.module.scss";

export default function SignIn() {
  const [state, setState] = useState({ email: "", password: "" });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [showSessionTimedOutMessage, setShowSessionTimedOutMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastInput>({
    message: "",
    status: "warn"
  });
  const { setRegisterEmail, setUser, setIsVerified } = useContext(UserContext);
  const navigate = useNavigate();
  const currentLocation = useLocation() as Location<LocationState>;

  const updateState = (state: { email?: string; password?: string }) => {
    setState((prevState) => {
      return { ...prevState, ...state };
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("token")) {
      setLoading(true);
      InvitesApi.confirmInvite(params.get("token") || "")
        .then(({ verified, email }: InviteVerification) => {
          setLoading(false);
          if (verified) {
            setReady(true);
          } else {
            void navigate(`/message/?expired=${email || ""}`);
          }
        })
        .catch((err: unknown) => {
          setLoading(false);
          setReady(true);
          setToastMessage({
            message: universalErrorHandlerToString(err),
            status: "warn"
          });
        });
    } else {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.moovExpired) {
      delete localStorage.moovExpired;
      setShowSessionTimedOutMessage(true);
    }
  }, []);

  useEffect(() => {
    if (state.email && state.password && disabled) {
      setDisabled(false);
    } else if ((!state.email || !state.password) && !disabled) {
      setDisabled(true);
    }
  }, [state]);

  const verifyAndNavigate = (user: User) => {
    return UsersApi.verifyDevice()
      .then((deviceVerifications: DeviceVerification[]) => {
        setUser(user);
        if (deviceVerifications.some(({ verified }) => verified)) {
          setIsVerified(true);
          void navigate(calculatePathToRedirectToAfterVerification(currentLocation, user));
        } else {
          // redundant but for easier understanding
          setIsVerified(false);
          void UsersApi.sendVerifyDeviceCode();
          void navigate("/session/two-factor", { state: currentLocation?.state });
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        setToastMessage({
          message: universalErrorHandlerToString(err),
          status: "warn"
        });
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.email && state.password) {
      setLoading(true);
      UsersApi.signIn(state.email, state.password)
        .then((user: User) => {
          setRegisterEmail(state.email);
          void verifyAndNavigate(user);
        })
        .catch((res: Response) => {
          setLoading(false);
          if (is4xx(res.status)) {
            setToastMessage({
              message: "Your email and/or password do not match.",
              status: "warn"
            });
          } else {
            setToastMessage({
              message: universalErrorHandlerToString(res),
              status: "warn"
            });
          }
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className={styles.layout}>
        <GradientBackground />
        <div className={styles.signInAuthContainer}>
          {!ready && <Loading centered />}
          {ready && (
            <>
              <header className={clsx(styles.logo, styles.logoCenter)}>
                <MoovLogo />
              </header>
              {showSessionTimedOutMessage && (
                <Alert className={styles.timeoutAlert} type="info">
                  We signed you out of your account after a period of inactivity.
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Toaster toastInput={toastMessage} />
                <FormGroup noGap={false}>
                  <FloatingLabelInput
                    label="Email"
                    name="email"
                    type="email"
                    pattern={REGEX.EMAIL}
                    required
                    value={state.email}
                    onChange={(e) => updateState({ email: e.target.value })}
                  />
                  <FloatingLabelPasswordInput
                    label="Password"
                    name="password"
                    required
                    value={state.password}
                    onChange={(e) => updateState({ password: e.target.value })}
                  />
                  <Button label="Sign in" fullWidth isLoading={loading} />
                </FormGroup>
              </Form>

              <p className={styles.authFooter}>
                <Link to="/forgotpass">Forgot password?</Link>
              </p>
              <footer className={styles.signUpCta}>
                <Text textStyle="body-medium">
                  Don't have an account? <Link to="/signup">Sign up for Moov</Link>
                </Text>
              </footer>
            </>
          )}
        </div>
        <p className={styles.googleDisclaimer}>
          This site is protected by reCAPTCHA. The Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>
      </div>
    </>
  );
}
