import type { Location } from "react-router";
import type { User } from "api/User.model";

export type LocationState = {
  previousUserID?: string;
  redirectLocationAfterLogin: string;
  activeUserAccountID?: string;
};

export const calculatePathToRedirectToAfterVerification = (
  currentLocation: Location<LocationState>,
  user?: User | null
): string => {
  // We need to default redirectLocationAfterLogin to "/" in the case the user went to "/signin" directly
  const {
    activeUserAccountID,
    previousUserID,
    redirectLocationAfterLogin = "/"
  } = currentLocation.state ?? {};

  // If there is no previousUserID (from a signout event), then we just redirect the user to the path they were trying to access
  if (!previousUserID) {
    return redirectLocationAfterLogin;
    // If the previousUserID is equal to the current user, then we still redirect them to the path they were trying to access
  } else if (user?.userID === previousUserID) {
    return redirectLocationAfterLogin;
    // Otherwise, there is a previousUserID, but it's not the current user, so we redirect to the homepage
  } else {
    if (activeUserAccountID === "moov-admin") return "/admin/accounts";
    return "/";
  }
};
